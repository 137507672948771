<template>
  <!-- 产业园区服务 -->
  <div class="IndustrialParkService">
    <!-- 服务介绍 -->
    <div class="ServiceIntroduction">
      <serviceTit ch="服务介绍" en="SERVICE INTRODUCTION"></serviceTit>
      <div class="servicecon">
        {{ resObj.caseColumn.ramark }}
      </div>
      <div class="s-cont">
        <b class="arrow arrow01"></b>
        <b class="arrow arrow02"></b>
        <div
          :class="['c-item c-item0' + (index - 0 + 1)]"
          v-for="(item, index) in resObj.caseColumnIntroduceList"
          :key="index"
        >
          <div class="i-info">
            <div class="info-img info-img1">
              <img
                :src="$store.state.baseUrl + item.imageUrl"
                alt="item.title"
              />
            </div>
            <div class="info-txt">
              <p>
                {{ item.infoDescribe }}
              </p>
            </div>
          </div>
          <div class="i-index">
            第{{
              index - 0 + 1 === 1 ? "一" : index - 0 + 1 === 2 ? "二" : "三"
            }}阶段
          </div>
          <div class="i-txt">{{ item.title }}</div>
        </div>
      </div>
    </div>
    <!-- 服务案例 -->
    <div class="ServiceCase">
      <serviceTit ch="服务案例" en="SERVICE CASE"></serviceTit>
      <serviceCase
        :caseObj="item"
        :index="index"
        v-for="(item, index) in resObj.caseList"
        :key="index"
      >
      </serviceCase>
    </div>
  </div>
</template>

<script>
import serviceTit from "@/components/common/servicetit";
import serviceCase from "@/components/common/serviceCase";

export default {
  data() {
    return {
      columnId: 55,
      pageNo: 1,
      pageSize: 8,
      servicecon:
        "具备17年管廊运维经验，先后承接广州、佛山、珠海等重点管廊项目。参与国家标准制定，主持编写地方标准，发起成立了中机维协管廊分会。",
      caseList: [
        {
          caseTitle: "咨询与技术服务",
          instructions:
            "主要为城市综合体项目设计和提供技术方案、技术指导、管理方案等。服务案例包括广州地铁集团交通枢纽中心、武汉光谷中心区地下综合管廊、安徽界首地下综合管廊、四川绵阳地下综合管廊、肇庆新区地下综合管廊、广西南宁地下综合管廊、华南理工大学研究广州管廊等众多项目。",
          imageList: [
            require("../../assets/images/serviceArea/service02/jishuyuzixun1.png"),
            require("../../assets/images/serviceArea/service02/jishuyuzixun2.png"),
            require("../../assets/images/serviceArea/service02/jishuyuzixun3.png"),
            require("../../assets/images/serviceArea/service02/jishuyuzixun4.png"),
          ],
        },
      ],
      resObj: {
        caseList: [],
        caseColumn: {},
        caseColumnIntroduceList: [],
      },
    };
  },
  mounted() {
    // console.log(this.CaseList);
    if (!!this.columnId) {
      this.getDataList();
    }
  },
  methods: {
    async getDataList() {
      // console.log(this.columnId);
      let res = await this.$request.getServiceTerritory({
        caseColumnId: this.columnId,
      });
      if (res.code === 200) {
        // console.log(res);
        // this.reseList = res.rows;
        // this.reseList.map(item => {
        //     item.imageList = item.imageUrl.split(",");
        // });
        this.resObj = res;
        this.resObj.caseList.map((item) => {
          item.imageList = item.imageUrl.split(",");
        });
        this.resObj.caseColumnIntroduceList.map((item) => {
          item.infoDescribe = JSON.parse(item.infoDescribe);
        });
      }
    },
  },
  components: { serviceTit, serviceCase },
};
</script>

<style lang="scss" scoped>
.IndustrialParkService {
  width: 62.5vw;
  margin: 0 auto;
  .ServiceIntroduction {
    padding: 2.08vw 0 0 0;
    .servicecon {
      line-height: 1.35vw;
      text-indent: 1.67vw;
      color: #333333;
      font-size: 0.83vw;
      padding: 0.78vw 0.78vw;
      margin-top: 1.04vw;
      background-color: #f5f5f5;
      position: relative;
      &::before {
        position: absolute;
        left: 0;
        bottom: 0;
        display: block;
        content: "";
        width: 0.78vw;
        height: 0.78vw;
        border-left: 0.1vw solid #f3ca00;
        border-bottom: 0.1vw solid #f3ca00;
      }
      &::after {
        position: absolute;
        right: 0;
        top: 0;
        display: block;
        content: "";
        width: 0.78vw;
        height: 0.78vw;
        border-right: 0.1vw solid #f3ca00;
        border-top: 0.1vw solid #f3ca00;
      }
    }
    .servicecon_ul {
      width: 100%;
      padding-bottom: 2.08vw;
      li {
        width: 20%;
        float: left;
        text-align: center;
        .servicecon_ul_img {
          width: 11.67vw;
          height: 7.29vw;
          overflow: hidden;
          &:hover img {
            transform: scale(1.06, 1.06);
          }
          img {
            transition: 0.6s ease-in-out;
          }
        }
        .servicecon_ul_tit {
          font-weight: bold;
        }
        .servicecon_ul_li {
          margin-top: 0.83vw;
          position: relative;
          line-height: 0.83vw;
          span {
            position: relative;
            &::before {
              display: inline-block;
              content: "";
              width: 0.26vw;
              height: 0.26vw;
              position: absolute;
              top: 50%;
              left: -0.52vw;
              margin: -0.13vw 0 0 0;
              background-color: #f3ca00;
              border-radius: 50%;
            }
          }
        }
      }
    }
  }
  .s-cont {
    padding: 2.08vw 0 2.08vw 0;
    position: relative;
    .arrow {
      display: block;
      width: 2.81vw;
      height: 2.19vw;
      background: url("../../assets/images/common/jiangtou.png") no-repeat;
      background-size: cover;
      &.arrow01 {
        position: absolute;
        top: 6.25vw;
        left: 18.23vw;
      }
      &.arrow02 {
        position: absolute;
        top: 6.25vw;
        left: 41.67vw;
      }
    }
    .c-item {
      display: inline-block;
      text-align: center;
      margin: 0 7.2vw 0 0;
      cursor: pointer;
      &:hover .i-info {
        .info-img {
          opacity: 0;
          z-index: -1;
        }
        .info-txt {
          opacity: 1;
          z-index: 99;
        }
      }
      &:last-child {
        margin: 0;
      }
      .i-info {
        width: 15.63vw;
        height: 10.42vw;
        position: relative;
        .info-img {
          display: block;
          width: 100%;
          height: 100%;
          overflow: hidden;
          position: absolute;
          top: 0;
          left: 0;
          transition: 0.4s ease-in-out;
          opacity: 1;
          z-index: 99;
          img {
            width: 100%;
          }
          // &.info-img1 {
          //     background: url("../../assets/images/serviceArea/service03/1.png")
          //         no-repeat;
          //     background-size: cover;
          // }
          // &.info-img2 {
          //     background: url("../../assets/images/serviceArea/service03/2.png")
          //         no-repeat;
          //     background-size: cover;
          // }
          // &.info-img3 {
          //     background: url("../../assets/images/serviceArea/service03/3.png")
          //         no-repeat;
          //     background-size: cover;
          // }
        }
        .info-txt {
          display: block;
          width: 13.54vw;
          height: 8.33vw;
          padding: 1.04vw;
          position: absolute;
          top: 0;
          left: 0;
          transition: 0.4s ease-in-out;
          opacity: 0;
          z-index: -1;
          background-color: #f3ca00;
          p {
            display: block;
            width: 11.46vw;
            height: 6.25vw;
            font-size: 0.83vw;
            line-height: 1.15vw;
            border: 0.05vw solid #fff;
            padding: 1.04vw;
            color: #333333;
            text-indent: 1.67vw;
            text-align: justify;
            word-break: normal;
          }
        }
      }
      .i-index {
        display: inline-block;
        font-size: 0.83vw;
        font-weight: bold;
        color: #333333;
        margin: 0.83vw 0 0 0;
        position: relative;
        &::before {
          content: "";
          display: block;
          width: 0.21vw;
          height: 0.83vw;
          position: absolute;
          top: 50%;
          left: -0.52vw;
          margin: -0.42vw 0 0 0;
          background-color: #f3ca00;
        }
      }
      .i-txt {
        font-size: 0.83vw;
        color: #333333;
        padding: 0.52vw 0;
      }
    }
  }
  .ServiceCase {
    position: relative;
    padding: 0 0 3.13vw 0;
  }
}
@media (min-width: 1920px) {
  .IndustrialParkService {
    width: 1200px;
    margin: 0 auto;
    .ServiceIntroduction {
      padding: 40px 0 0 0;
      .servicecon {
        line-height: 26px;
        text-indent: 32px;
        color: #333333;
        font-size: 16px;
        padding: 15px 15px;
        margin-top: 20px;
        background-color: #f5f5f5;
        position: relative;
        &::before {
          position: absolute;
          left: 0;
          bottom: 0;
          display: block;
          content: "";
          width: 15px;
          height: 15px;
          border-left: 2px solid #f3ca00;
          border-bottom: 2px solid #f3ca00;
        }
        &::after {
          position: absolute;
          right: 0;
          top: 0;
          display: block;
          content: "";
          width: 15px;
          height: 15px;
          border-right: 2px solid #f3ca00;
          border-top: 2px solid #f3ca00;
        }
      }
      .servicecon_ul {
        width: 100%;
        padding-bottom: 40px;
        li {
          width: 20%;
          float: left;
          text-align: center;
          .servicecon_ul_img {
            width: 224px;
            height: 140px;
            overflow: hidden;
            &:hover img {
              transform: scale(1.06, 1.06);
            }
            img {
              transition: 0.6s ease-in-out;
            }
          }
          .servicecon_ul_tit {
            font-weight: bold;
          }
          .servicecon_ul_li {
            margin-top: 16px;
            position: relative;
            line-height: 16px;
            span {
              position: relative;
              &::before {
                display: inline-block;
                content: "";
                width: 5px;
                height: 5px;
                position: absolute;
                top: 50%;
                left: -10px;
                margin: -2.5px 0 0 0;
                background-color: #f3ca00;
                border-radius: 50%;
              }
            }
          }
        }
      }
      .s-cont {
        padding: 40px 0 40px 0;
        position: relative;
        .arrow {
          display: block;
          width: 54px;
          height: 42px;
          background: url("../../assets/images/common/jiangtou.png") no-repeat;
          background-size: cover;
          &.arrow01 {
            position: absolute;
            top: 120px;
            left: 350px;
          }
          &.arrow02 {
            position: absolute;
            top: 120px;
            left: 800px;
          }
        }
        .c-item {
          display: inline-block;
          text-align: center;
          margin: 0 144px 0 0;
          /* cursor: pointer; */
          &:hover .i-info {
            .info-img {
              opacity: 0;
              z-index: -1;
            }
            .info-txt {
              opacity: 1;
              z-index: 99;
            }
          }
          &.c-item03 {
            margin: 0;
          }
          .i-info {
            width: 300px;
            height: 200px;
            position: relative;
            .info-img {
              display: block;
              width: 100%;
              height: 100%;
              overflow: hidden;
              position: absolute;
              top: 0;
              left: 0;
              transition: 0.4s ease-in-out;
              opacity: 1;
              z-index: 99;
              img {
                width: 100%;
              }
              // &.info-img1 {
              //     background: url("../../assets/images/serviceArea/service03/1.png")
              //         no-repeat;
              //     background-size: cover;
              // }
              // &.info-img2 {
              //     background: url("../../assets/images/serviceArea/service03/2.png")
              //         no-repeat;
              //     background-size: cover;
              // }
              // &.info-img3 {
              //     background: url("../../assets/images/serviceArea/service03/3.png")
              //         no-repeat;
              //     background-size: cover;
              // }
            }
            .info-txt {
              display: block;
              width: 260px;
              height: 160px;
              padding: 20px;
              position: absolute;
              top: 0;
              left: 0;
              transition: 0.4s ease-in-out;
              opacity: 0;
              z-index: -1;
              background-color: #f3ca00;
              p {
                display: block;
                width: 220px;
                height: 120px;
                font-size: 16px;
                line-height: 22px;
                border: 1px solid #fff;
                padding: 20px;
                color: #333333;
                text-indent: 32px;
                text-align: justify;
                word-break: normal;
              }
            }
          }
          .i-index {
            display: inline-block;
            font-size: 16px;
            font-weight: bold;
            color: #333333;
            margin: 16px 0 0 0;
            position: relative;
            &::before {
              content: "";
              display: block;
              width: 4px;
              height: 16px;
              position: absolute;
              top: 50%;
              left: -10px;
              margin: -8px 0 0 0;
              background-color: #f3ca00;
            }
          }
          .i-txt {
            font-size: 16px;
            color: #333333;
            padding: 10px 0;
          }
        }
      }
    }
    .ServiceCase {
      position: relative;
      padding: 0 0 60px 0;
    }
  }
}
</style>
