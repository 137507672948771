<template>
    <!-- 城市综合体运维 -->
    <div class="OperationAndMaintenance">
        <!-- 服务介绍 -->
        <div class="ServiceIntroduction">
            <serviceTit ch="服务介绍" en="SERVICE INTRODUCTION"></serviceTit>
            <div class="servicecon">
                {{ resObj.caseColumn.ramark }}
            </div>
            <ul class=" servicecon_ul  marginT30 clear">
                <li
                    v-for="(item, index) in resObj.caseColumnIntroduceList"
                    :key="index"
                >
                    <div class="servicecon_ul_img">
                        <img :src="$store.state.baseUrl + item.imageUrl" />
                    </div>
                    <div class="servicecon_ul_con">
                        <div
                            class="servicecon_ul_tit color333 fontsize18 marginT15"
                        >
                            {{ item.title }}
                        </div>
                        <div
                            v-for="(item1, index1) in item.infoDescribe"
                            :key="index1"
                            class="servicecon_ul_li color666 fontsize16"
                        >
                            <span>{{ item1 }}</span>
                        </div>
                    </div>
                </li>
            </ul>
        </div>
        <!-- 服务案例 -->
        <div class="ServiceCase">
            <serviceTit ch="服务案例" en="SERVICE CASE"></serviceTit>
            <serviceCase
                :caseObj="item"
                :index="index"
                v-for="(item, index) in resObj.caseList"
                :key="index"
            >
            </serviceCase>
        </div>
    </div>
</template>

<script>
import serviceTit from "@/components/common/servicetit";
import serviceCase from "@/components/common/serviceCase";

export default {
    data() {
        return {
            columnId: 52,
            pageNo: 1,
            pageSize: 8,
            servicecon:
                "连续18年为广交会展馆提供综合设备管理，具备城市综合体智能化、暖通、电气等各专业管理经验。政治素养高、经验丰富、技术突出、安全高效。",
            serviceIntroduction: [
                {
                    imgsrc: require("../../assets/images/serviceArea/service01/zixun.png"),
                    infotxt: "咨询服务",
                    infoArr: ["方案优化", "费用测算", "人员培训"]
                },
                {
                    imgsrc: require("../../assets/images/serviceArea/service01/jishu.png"),
                    infotxt: "技术服务",
                    infoArr: ["技术编制", "技术支持", "技术诊断"]
                },
                {
                    imgsrc: require("../../assets/images/serviceArea/service01/biaozhun.png"),
                    infotxt: "标准服务",
                    infoArr: ["规范起草", "标准编写", "标准审核"]
                },
                {
                    imgsrc: require("../../assets/images/serviceArea/service01/yunwei.png"),
                    infotxt: "运维服务",
                    infoArr: ["委托运维", "设备点检", "配件供应"]
                },
                {
                    imgsrc: require("../../assets/images/serviceArea/service01/gongcheng.png"),
                    infotxt: "工程服务",
                    infoArr: ["工程施工", "设备检修", "项目管理"]
                }
            ],
            caseList: [
                {
                    caseTitle: "大型场馆",
                    instructions:
                        "<p><strong>中国进出口商品交易会(广交会)展馆:</strong>作为机电设备运维行业的开拓者，我司机电运维事业部连续17年为广交会展馆提供设备设施综合运行管理服务，先后保障34届广交会及各项重大展览活动的举办，多次被评为广交会优秀外包单位，是广交会多年发展最值得信赖的合作伙伴之一。</p>",
                    imageList: [
                        require("../../assets/images/serviceArea/service01/daxingchangguan1.png"),
                        require("../../assets/images/serviceArea/service01/daxingchangguan2.png"),
                        require("../../assets/images/serviceArea/service01/daxingchangguan3.png"),
                        require("../../assets/images/serviceArea/service01/daxingchangguan4.png")
                    ]
                },
                {
                    caseTitle: "商业综合体",
                    instructions:
                        "<p><strong>广州太古汇：</strong>位于广州市天河中央商务区核心地段，总楼面面积约35.8万平方米，包括大型购物商场、甲级办公楼、酒店及酒店式服务住宅等。我司机电运维事业部作为全球房地产咨询五大行之一仲量联行的合格供应商，为广州太古汇提供供电、空调等系统的运维保养服务。</p>",
                    imageList: [
                        require("../../assets/images/serviceArea/service01/taiguhui1.png"),
                        require("../../assets/images/serviceArea/service01/taiguhui2.png"),
                        require("../../assets/images/serviceArea/service01/taiguhui3.png"),
                        require("../../assets/images/serviceArea/service01/taiguhui4.png")
                    ]
                }
            ],
            resObj: {
                caseList: [],
                caseColumn: {},
                caseColumnIntroduceList: []
            }
        };
    },
    mounted() {
        // console.log(this.CaseList);
        if (!!this.columnId) {
            this.getDataList();
        }
    },
    methods: {
        async getDataList() {
            // console.log(this.columnId);
            let res = await this.$request.getServiceTerritory({
                caseColumnId: this.columnId
            });
            if (res.code === 200) {
                // console.log(res);
                // this.reseList = res.rows;
                // this.reseList.map(item => {
                //     item.imageList = item.imageUrl.split(",");
                // });
                this.resObj = res;
                this.resObj.caseList.map(item => {
                    item.imageList = item.imageUrl.split(",");
                });
                this.resObj.caseColumnIntroduceList.map(item => {
                    item.infoDescribe = JSON.parse(item.infoDescribe);
                });
            }
        }
    },
    components: { serviceTit, serviceCase }
};
</script>

<style lang="scss" scoped>
.OperationAndMaintenance {
    width: 62.5vw;
    margin: 0 auto;
    .ServiceIntroduction {
        padding: 2.08vw 0 0 0;
        .servicecon {
            line-height: 1.25vw;
            text-indent: 1.67vw;
            color: #333333;
            font-size: 0.83vw;
            padding: 0.83vw 0.78vw;
            margin-top: 1.04vw;
            background-color: #f5f5f5;
            position: relative;
            text-align: justify;
            word-break: normal;
            &::before {
                position: absolute;
                left: 0;
                bottom: 0;
                display: block;
                content: "";
                width: 0.78vw;
                height: 0.78vw;
                border-left: 0.1vw solid #f3ca00;
                border-bottom: 0.1vw solid #f3ca00;
            }
            &::after {
                position: absolute;
                right: 0;
                top: 0;
                display: block;
                content: "";
                width: 0.78vw;
                height: 0.78vw;
                border-right: 0.1vw solid #f3ca00;
                border-top: 0.1vw solid #f3ca00;
            }
        }
        .servicecon_ul {
            width: 100%;
            padding-bottom: 2.08vw;
            li {
                width: 20%;
                float: left;
                text-align: center;
                .servicecon_ul_img {
                    width: 11.67vw;
                    height: 7.29vw;
                    overflow: hidden;
                    &:hover img {
                        transform: scale(1.06, 1.06);
                    }
                    img {
                        transition: 0.6s ease-in-out;
                    }
                }
                .servicecon_ul_tit {
                    font-weight: bold;
                }
                .servicecon_ul_li {
                    margin-top: 0.83vw;
                    position: relative;
                    line-height: 0.83vw;
                    span {
                        position: relative;
                        &::before {
                            display: inline-block;
                            content: "";
                            width: 0.26vw;
                            height: 0.26vw;
                            position: absolute;
                            top: 50%;
                            left: -0.52vw;
                            margin: -0.13vw 0 0 0;
                            background-color: #f3ca00;
                            border-radius: 50%;
                        }
                    }
                }
            }
        }
    }
    .ServiceCase {
        position: relative;
        padding-bottom: 3.13vw;
    }
}
@media (min-width: 1920px) {
    .OperationAndMaintenance {
        width: 1200px;
        margin: 0 auto;
        .ServiceIntroduction {
            padding: 40px 0 0 0;
            .servicecon {
                line-height: 24px;
                text-indent: 32px;
                color: #333333;
                font-size: 16px;
                padding: 16px 15px;
                margin-top: 20px;
                background-color: #f5f5f5;
                position: relative;
                text-align: justify;
                word-break: normal;
                &::before {
                    position: absolute;
                    left: 0;
                    bottom: 0;
                    display: block;
                    content: "";
                    width: 15px;
                    height: 15px;
                    border-left: 2px solid #f3ca00;
                    border-bottom: 2px solid #f3ca00;
                }
                &::after {
                    position: absolute;
                    right: 0;
                    top: 0;
                    display: block;
                    content: "";
                    width: 15px;
                    height: 15px;
                    border-right: 2px solid #f3ca00;
                    border-top: 2px solid #f3ca00;
                }
            }
            .servicecon_ul {
                width: 100%;
                padding-bottom: 40px;
                li {
                    width: 20%;
                    float: left;
                    text-align: center;
                    .servicecon_ul_img {
                        width: 224px;
                        height: 140px;
                        overflow: hidden;
                        &:hover img {
                            transform: scale(1.06, 1.06);
                        }
                        img {
                            transition: 0.6s ease-in-out;
                        }
                    }
                    .servicecon_ul_tit {
                        font-weight: bold;
                    }
                    .servicecon_ul_li {
                        margin-top: 16px;
                        position: relative;
                        line-height: 16px;
                        span {
                            position: relative;
                            &::before {
                                display: inline-block;
                                content: "";
                                width: 5px;
                                height: 5px;
                                position: absolute;
                                top: 50%;
                                left: -10px;
                                margin: -2.5px 0 0 0;
                                background-color: #f3ca00;
                                border-radius: 50%;
                            }
                        }
                    }
                }
            }
        }
        .ServiceCase {
            position: relative;
            padding-bottom: 60px;
        }
    }
}
</style>
