<template>
  <!-- 服务领域 -->
  <div class="serviceArea">
    <Banner
      :banner_img="resBanners"
      banner_en="Service Area"
      banner_cn="服务领域"
    >
    </Banner>
    <div class="pnav clear" v-if="caseColumn.length > 0">
      <div
        :class="[
          item.retainfield1 === componentTag.retainfield1 ? 'on' : '',
          'navItem',
        ]"
        @click="OnChangeCont(item)"
        v-for="(item, index) in caseColumn"
        :key="index"
      >
        <router-link class="nuxtLink" :to="'/serviceArea?key=' + item.retainfield1">
          {{ item.columnName }}
        </router-link>
      </div>
    </div>

    <!-- 核心模块 -->
    <component
      :is="componentTag.retainfield1"
      class="wow fadeInUp animated"
    ></component>
  </div>
</template>

<script>
import PipeGallery from "@/components/serviceArea/PipeGallery";
import OperationAndMaintenance from "@/components/serviceArea/OperationAndMaintenance";
import IndustryParkService from "@/components/serviceArea/IndustryParkService";
import FactoryService from "@/components/serviceArea/FactoryService";
import IndustrialParkService from "@/components/serviceArea/IndustrialParkService";
import OfficeServices from "@/components/serviceArea/OfficeServices";
import MallService from "@/components/serviceArea/MallService";
import PrivateLabelService from "@/components/serviceArea/PrivateLabelService";
import Banner from "@/components/common/Banner.vue";

export default {
  data() {
    return {
      componentTag: {
        columnName: "城市综合体运维",
        retainfield1: "OperationAndMaintenance",
      },
      // pnavList: [
      //     {
      //         index: 0,
      //         columnName: "城市综合体运维",
      //         retainfield1: "OperationAndMaintenance"
      //     },
      //     {
      //         index: 1,
      //         columnName: "城市综合管廊运维",
      //         retainfield1: "PipeGallery"
      //     },
      //     {
      //         index: 2,
      //         columnName: "工业园区服务",
      //         retainfield1: "IndustryParkService"
      //     },
      //     {
      //         index: 3,
      //         columnName: "工厂运维服务",
      //         retainfield1: "FactoryService"
      //     },
      //     {
      //         index: 4,
      //         columnName: "产业园区服务",
      //         retainfield1: "IndustrialParkService"
      //     },
      //     {
      //         index: 5,
      //         columnName: "高端写字楼服务",
      //         retainfield1: "OfficeServices"
      //     },
      //     {
      //         index: 6,
      //         columnName: "在线商城",
      //         retainfield1: "MallService"
      //     },
      //     {
      //         index: 7,
      //         columnName: "生活服务",
      //         retainfield1: "PrivateLabelService"
      //     }
      // ],
      caseColumn: [],
      resBanners: [],
    };
  },
  mounted() {
    this.getCaseColumn();
    // console.log(this.$router);
    this.getBanners();
    new this.$wow.WOW({
      live: false,
      offset: 0,
    }).init();
  },
  methods: {
    OnChangeCont(data) {
      this.componentTag = data;
    },
    // 获取banner
    async getBanners() {
      let res = await this.$request.getImageAdvertisingList({
        advertisingPosition: "3",
      });
      if (res.code === 200) {
        this.resBanners = [];
        this.resBanners = res.rows;
      }
    },
    // 获取栏目列表
    async getCaseColumn() {
      let res = await this.$request.getCaseColumn();
      // let dataList = [];
      if (res.code === 200) {
        // console.log(res);
        this.caseColumn = res.data;
        // console.log(this.caseColumn);
        let resList = this.caseColumn.filter((item) => {
          // console.log(this.$route.query.key);
          // console.log(item);
          // console.log(this.$route.query.key === item.retainfield1);
          return this.$route.query.key === item.retainfield1;
        });
        // console.log(resList);
        this.componentTag = resList[0];
      }
    },
  },
  components: {
    PipeGallery,
    OperationAndMaintenance,
    IndustryParkService,
    FactoryService,
    IndustrialParkService,
    OfficeServices,
    MallService,
    PrivateLabelService,
    Banner,
  },
};
</script>

<style lang="scss" scoped>
.serviceArea {
  max-width: 100%;
  overflow: hidden;
  .pnav {
    display: table;
    width: auto;
    height: 2.92vw;
    // line-height: 2.92vw;
    cursor: pointer;
    text-align: center;
    margin: -2.92vw auto 0 auto;
    color: #333333;
    position: relative;
    z-index: 11;
    font-size: 0;
    word-spacing: -0.05vw;
    text-align: center;
    .navItem {
      display: table-cell;
      vertical-align: middle;
      width: 7.81vw;
      border: 0.05vw solid #eee;
      background-color: #ffffff;
      &.on {
        color: #333333;
        border: 0.05vw solid #f3ca00;
        background-color: #f3ca00;
      }
      .nuxtLink {
        display: inline-block;
        font-size: 0.83vw;
        line-height: 1.25vw;
      }
    }
  }
}
@media (min-width: 1920px) {
  .serviceArea {
    max-width: 100%;
    overflow: hidden;
    .pnav {
      display: table;
      width: auto;
      height: 56px;
      // line-height: 56px;
      cursor: pointer;
      text-align: center;
      margin: -56px auto 0 auto;
      color: #333333;
      position: relative;
      z-index: 11;
      font-size: 0;
      word-spacing: -1px;
      text-align: center;
      .navItem {
        display: table-cell;
        vertical-align: middle;
        width: 150px;
        border: 1px solid #eee;
        font-size: 16px;
        background-color: #ffffff;
        &.on {
          color: #333333;
          border: 1px solid #f3ca00;
          background-color: #f3ca00;
        }
        .nuxtLink {
          display: inline-block;
          font-size: 16px;
          line-height: 20px;
        }
      }
    }
  }
}
</style>
