<template>
    <!-- 工业园区服务 -->
    <div class="IndustryParkService">
        <!-- 服务介绍 -->
        <div class="ServiceIntroduction">
            <serviceTit ch="服务介绍" en="SERVICE INTRODUCTION"></serviceTit>
            <div class="servicecon">
                {{ resObj.caseColumn.ramark }}
            </div>
            <!-- <div class="s-cont">
                <b class="arrow arrow01"></b>
                <b class="arrow arrow02"></b>
                <div :class="['c-item c-item0'+(index-0+1)]" v-for="(item,index) in  resObj.caseColumnIntroduceList" :key="index">
                    <div class="i-info">
                        <div class="info-img info-img1">
                            <img :src="$store.state.baseUrl + item.imageUrl" alt="item.title">
                        </div>
                        <div class="info-txt">
                            <p>
                                {{item.infoDescribe}}
                            </p>
                        </div>
                    </div>
                    <div class="i-index">第{{index-0+1===1?'一':index-0+1===2?'二':'三'}}阶段</div>
                    <div class="i-txt">{{item.title}}</div>
                </div>
            </div> -->
        </div>
        <!-- 服务案例 -->
        <div class="ServiceCase">
            <serviceTit ch="服务案例" en="SERVICE CASE"></serviceTit>
            <serviceCase
                :caseObj="item"
                :index="index"
                v-for="(item, index) in resObj.caseList"
                :key="index"
            >
            </serviceCase>
        </div>
    </div>
</template>

<script>
import serviceTit from "@/components/common/servicetit";
import serviceCase from "@/components/common/serviceCase";

export default {
    data() {
        return {
            columnId: 54,
            pageNo: 1,
            pageSize: 8,
            serviceIntroduction: [
                {
                    imageUrl: require("../../assets/images/serviceArea/service03/1.png"),
                    title: "园区规划设计与开发建设阶段",
                    infoDescribe: "提供前期介入工程服务、房屋交楼验收、设备设施承接查验等服务；"
                },
                {
                    imageUrl: require("../../assets/images/serviceArea/service03/2.png"),
                    title: "园区招商阶段",
                    infoDescribe: "提供销售案场服务、活动筹划实施等服务；"
                },
                {
                    imageUrl: require("../../assets/images/serviceArea/service03/3.png"),
                    title: "园区正式投入使用阶段",
                    infoDescribe: "提供客服接待、公共秩序维护、设施设备运维等，助力园区的招商引资及产业生态圈的打造，以帮助实现园区的可持续发展。"
                },
            ],
            servicecon:
                "面向科技园、产业园、创意园等提供园区全生命周期的运营服务。在园区规划设计与开发建设阶段，提供前期介入工程服务、房屋交楼验收、设备设施承接查验；在园区招商阶段，提供销售案场服务、活动筹划实施等；在园区正式投入使用阶段，提供客服接待、公共秩序维护、设施设备运维、绿化养护和园林工程、园区一卡通、物业资产运营、大型会务及活动组织、员工餐饮、交通接驳、公寓酒店管理、商铺服务等，助力园区的招商引资及产业生态圈的打造，以帮助实现园区的可持续发展。",
            caseList: [
                {
                    caseTitle: "岭南V谷",
                    instructions:
                        "岭南V谷·广州国际智能科技园：广州市政府重点项目,总占地面积约22万㎡，总建筑面积超53万㎡，具有多功能复合业态，包括独栋写字楼、产业办公楼、人才公寓、滨水商业街、精品酒店。工控服务将为园区提供全生命周期的物业运营服务。",
                    imageList: [
                        require("../../assets/images/serviceArea/service03/lingnan1.png"),
                        require("../../assets/images/serviceArea/service03/lingnan2.png"),
                        require("../../assets/images/serviceArea/service03/lingnan3.png"),
                        require("../../assets/images/serviceArea/service03/lingnan4.png")
                    ]
                },
                {
                    caseTitle: "万宝番禺生产基地",
                    instructions:
                        "万宝番禺生产基地：1994年建成开始投入使用，规划总用地面积约76万平方米，总建筑面积约98.96万平方米，员工约1.6万人。我司物业管理事业部承担着番禺基地年产值约100亿的综合服务保障。",
                    imageList: [
                        require("../../assets/images/serviceArea/service03/wanbao1.png"),
                        require("../../assets/images/serviceArea/service03/wanbao2.png"),
                        require("../../assets/images/serviceArea/service03/wanbao3.png"),
                        require("../../assets/images/serviceArea/service03/wanbao4.png")
                    ]
                },
                {
                    caseTitle: "金邦园区",
                    instructions:
                        "金邦园区：管理面积约17万平方米，我司物业管理事业部为园区内的大型工厂和制造型企业提供秩序维护，清洁绿化，管道疏通清洗等服务，以保障园区安全、高效的运行。",
                    imageList: [
                        require("../../assets/images/serviceArea/service03/jinbang1.png"),
                        require("../../assets/images/serviceArea/service03/jinbang2.png"),
                        require("../../assets/images/serviceArea/service03/jinbang3.png"),
                        require("../../assets/images/serviceArea/service03/jinbang4.png")
                    ]
                },
                {
                    caseTitle: "JFE工厂",
                    instructions:
                        "JFE工厂：是华南地区著名生产企业，总占地面积约53公顷。我司机电运维事业部为工厂提供大型设备检修、安装、维护及部分生产工序的外包服务，物业管理事业部为整个厂区提供清洁绿化、秩序维护、工程运维、前台客服等服务。多年来的贴心服务，深受客户好评。",
                    imageList: [
                        require("../../assets/images/serviceArea/service03/JEF1.png"),
                        require("../../assets/images/serviceArea/service03/JEF2.png"),
                        require("../../assets/images/serviceArea/service03/JEF3.png"),
                        require("../../assets/images/serviceArea/service03/JEF4.png")
                    ]
                }
            ],
            resObj:{
                caseList:[],
                caseColumn:{},
                caseColumnIntroduceList:[]
            }
        };
    },
    mounted() {
        // console.log(this.CaseList);
        if (!!this.columnId) {
            this.getDataList();
        }
    },
    methods: {
        async getDataList() {
            // console.log(this.columnId);
            let res = await this.$request.getServiceTerritory({
                caseColumnId: this.columnId
            });
            if (res.code === 200) {
                // console.log(res);
                // this.reseList = res.rows;
                // this.reseList.map(item => {
                //     item.imageList = item.imageUrl.split(",");
                // });
                this.resObj = res
                this.resObj.caseList.map(item => {
                    item.imageList = item.imageUrl.split(",");
                });
            }
        }
    },
    components: { serviceTit, serviceCase }
};
</script>

<style lang="scss" scoped>
.IndustryParkService {
    width: 62.5vw;
    margin: 0 auto;
    .ServiceIntroduction {
        padding: 2.08vw 0 0 0;
        .servicecon {
            line-height: 1.25vw;
            text-indent: 1.67vw;
            color: #333333;
            font-size: 0.83vw;
            padding: 0.83vw 0.78vw;
            margin-top: 1.04vw;
            background-color: #f5f5f5;
            position: relative;
            text-align: justify;
            word-break: normal;
            &::before {
                position: absolute;
                left: 0;
                bottom: 0;
                display: block;
                content: "";
                width: 0.78vw;
                height: 0.78vw;
                border-left: 0.1vw solid #f3ca00;
                border-bottom: 0.1vw solid #f3ca00;
            }
            &::after {
                position: absolute;
                right: 0;
                top: 0;
                display: block;
                content: "";
                width: 0.78vw;
                height: 0.78vw;
                border-right: 0.1vw solid #f3ca00;
                border-top: 0.1vw solid #f3ca00;
            }
        }
        .s-cont {
            padding: 2.08vw 0 2.08vw 0;
            position: relative;
            .arrow {
                display: block;
                width: 2.81vw;
                height: 2.19vw;
                background: url("../../assets/images/common/jiangtou.png") no-repeat;
                background-size: cover;
                &.arrow01 {
                    position: absolute;
                    top: 6.25vw;
                    left: 18.23vw;
                }
                &.arrow02 {
                    position: absolute;
                    top: 6.25vw;
                    left: 41.67vw;
                }
            }
            .c-item {
                display: inline-block;
                text-align: center;
                margin: 0 7.2vw 0 0;
                cursor: pointer;
                &:hover .i-info {
                    .info-img {
                        opacity: 0;
                        z-index: -1;
                    }
                    .info-txt {
                        opacity: 1;
                        z-index: 99;
                    }
                }
                &:last-child {
                    margin: 0;
                }
                .i-info {
                    width: 15.63vw;
                    height: 10.42vw;
                    position: relative;
                    .info-img {
                        display: block;
                        width: 100%;
                        height: 100%;
                        overflow: hidden;
                        position: absolute;
                        top: 0;
                        left: 0;
                        transition: 0.4s ease-in-out;
                        opacity: 1;
                        z-index: 99;
                        img{
                            width: 100%;
                        }
                        // &.info-img1 {
                        //     background: url("../../assets/images/serviceArea/service03/1.png")
                        //         no-repeat;
                        //     background-size: cover;
                        // }
                        // &.info-img2 {
                        //     background: url("../../assets/images/serviceArea/service03/2.png")
                        //         no-repeat;
                        //     background-size: cover;
                        // }
                        // &.info-img3 {
                        //     background: url("../../assets/images/serviceArea/service03/3.png")
                        //         no-repeat;
                        //     background-size: cover;
                        // }
                    }
                    .info-txt {
                        display: block;
                        width: 13.54vw;
                        height: 8.33vw;
                        padding: 1.04vw;
                        position: absolute;
                        top: 0;
                        left: 0;
                        transition: 0.4s ease-in-out;
                        opacity: 0;
                        z-index: -1;
                        background-color: #f3ca00;
                        p {
                            display: block;
                            width: 11.46vw;
                            height: 6.25vw;
                            font-size: 0.83vw;
                            line-height: 1.15vw;
                            border: 0.05vw solid #fff;
                            padding: 1.04vw;
                            color: #333333;
                            text-indent: 1.67vw;
                            text-align: justify;
                            word-break: normal;
                        }
                    }
                }
                .i-index {
                    display: inline-block;
                    font-size: 0.83vw;
                    font-weight: bold;
                    color: #333333;
                    margin: 0.83vw 0 0 0;
                    position: relative;
                    &::before {
                        content: "";
                        display: block;
                        width: 0.21vw;
                        height: 0.83vw;
                        position: absolute;
                        top: 50%;
                        left: -0.52vw;
                        margin: -0.42vw 0 0 0;
                        background-color: #f3ca00;
                    }
                }
                .i-txt {
                    font-size: 0.83vw;
                    color: #333333;
                    padding: 0.52vw 0;
                }
            }
        }
    }
    .ServiceCase {
        position: relative;
        padding: 3.13vw 0;
    }
}
@media (min-width: 1920px) {
    .IndustryParkService {
        width: 1200px;
        margin: 0 auto;
        .ServiceIntroduction {
            padding: 40px 0 0 0;
            .servicecon {
                line-height: 24px;
                text-indent: 32px;
                color: #333333;
                font-size: 16px;
                padding: 16px 15px;
                margin-top: 20px;
                background-color: #f5f5f5;
                position: relative;
                text-align: justify;
                word-break: normal;
                &::before {
                    position: absolute;
                    left: 0;
                    bottom: 0;
                    display: block;
                    content: "";
                    width: 15px;
                    height: 15px;
                    border-left: 2px solid #f3ca00;
                    border-bottom: 2px solid #f3ca00;
                }
                &::after {
                    position: absolute;
                    right: 0;
                    top: 0;
                    display: block;
                    content: "";
                    width: 15px;
                    height: 15px;
                    border-right: 2px solid #f3ca00;
                    border-top: 2px solid #f3ca00;
                }
            }
            .s-cont {
                padding: 40px 0 40px 0;
                position: relative;
                .arrow {
                    display: block;
                    width: 54px;
                    height: 42px;
                    background: url("../../assets/images/common/jiangtou.png")
                        no-repeat;
                    background-size: cover;
                    &.arrow01 {
                        position: absolute;
                        top: 120px;
                        left: 350px;
                    }
                    &.arrow02 {
                        position: absolute;
                        top: 120px;
                        left: 800px;
                    }
                }
                .c-item {
                    display: inline-block;
                    text-align: center;
                    margin: 0 144px 0 0;
                    /* cursor: pointer; */
                    &:hover .i-info {
                        .info-img {
                            opacity: 0;
                            z-index: -1;
                        }
                        .info-txt {
                            opacity: 1;
                            z-index: 99;
                        }
                    }
                    &.c-item03 {
                        margin: 0;
                    }
                    .i-info {
                        width: 300px;
                        height: 200px;
                        position: relative;
                        .info-img {
                            display: block;
                            width: 100%;
                            height: 100%;
                            overflow: hidden;
                            position: absolute;
                            top: 0;
                            left: 0;
                            transition: 0.4s ease-in-out;
                            opacity: 1;
                            z-index: 99;
                            img{
                                width: 100%;
                            }
                            // &.info-img1 {
                            //     background: url("../../assets/images/serviceArea/service03/1.png")
                            //         no-repeat;
                            //     background-size: cover;
                            // }
                            // &.info-img2 {
                            //     background: url("../../assets/images/serviceArea/service03/2.png")
                            //         no-repeat;
                            //     background-size: cover;
                            // }
                            // &.info-img3 {
                            //     background: url("../../assets/images/serviceArea/service03/3.png")
                            //         no-repeat;
                            //     background-size: cover;
                            // }
                        }
                        .info-txt {
                            display: block;
                            width: 260px;
                            height: 160px;
                            padding: 20px;
                            position: absolute;
                            top: 0;
                            left: 0;
                            transition: 0.4s ease-in-out;
                            opacity: 0;
                            z-index: -1;
                            background-color: #f3ca00;
                            p {
                                display: block;
                                width: 220px;
                                height: 120px;
                                font-size: 16px;
                                line-height: 22px;
                                border: 1px solid #fff;
                                padding: 20px;
                                color: #333333;
                                text-indent: 32px;
                                text-align: justify;
                                word-break: normal;
                            }
                        }
                    }
                    .i-index {
                        display: inline-block;
                        font-size: 16px;
                        font-weight: bold;
                        color: #333333;
                        margin: 16px 0 0 0;
                        position: relative;
                        &::before {
                            content: "";
                            display: block;
                            width: 4px;
                            height: 16px;
                            position: absolute;
                            top: 50%;
                            left: -10px;
                            margin: -8px 0 0 0;
                            background-color: #f3ca00;
                        }
                    }
                    .i-txt {
                        font-size: 16px;
                        color: #333333;
                        padding: 10px 0;
                    }
                }
            }
        }
        .ServiceCase {
            position: relative;
            padding: 60px 0;
        }
    }
}
</style>
