<template>
    <!-- 高端写字楼服务 -->
    <div class="OfficeServices">
        <!-- 服务介绍 -->
        <div class="ServiceIntroduction">
            <serviceTit ch="服务介绍" en="SERVICE INTRODUCTION"></serviceTit>
            <div class="servicecon">
                {{ resObj.caseColumn.ramark }}
            </div>
            <ul class="servicecon_ul  marginT30 clear">
                <li
                    v-for="(item, index) in resObj.caseColumnIntroduceList"
                    :key="index"
                >
                    <div class="servicecon_ul_img">
                        <img :src="$store.state.baseUrl + item.imageUrl" />
                    </div>
                    <div class="servicecon_ul_con">
                        <div
                            class="servicecon_ul_tit color333 fontsize18 marginT15"
                        >
                            {{ item.title }}
                        </div>
                        <div class="servicecon_ul_li color666 fontsize16">
                            <span>{{ item.infoDescribe }}</span>
                        </div>
                    </div>
                </li>
            </ul>
            <div class="list_icon">
                <b class="icon_bg"></b>
                <div
                    class="item_icon"
                    v-for="(item, index) in iconList"
                    :key="index"
                >
                    <div class="i_text">{{ item.text }}</div>
                    <div class="i_icon">
                        <img :src="item.imgsrc" :alt="item.text" />
                    </div>
                </div>
            </div>
        </div>
        <!-- 服务案例 -->
        <div class="ServiceCase">
            <serviceTit ch="服务案例" en="SERVICE CASE"></serviceTit>
            <serviceCase
                :caseObj="item"
                :index="index"
                v-for="(item, index) in resObj.caseList"
                :key="index"
            >
            </serviceCase>
        </div>
    </div>
</template>

<script>
import serviceTit from "@/components/common/servicetit";
import serviceCase from "@/components/common/serviceCase";

export default {
    data() {
        return {
            columnId: 56,
            pageNo: 1,
            pageSize: 8,
            servicecon:
                "以高端写字楼物业服务为基础，打造企业非核心业务外包服务链，包括公共秩序维护、清洁、绿化、设施设备维保、客户服务等，以及业主专项委托的水电维修等。",
            serviceIntroduction: [
                {
                    imgsrc: require("../../assets/images/serviceArea/service06/2.1.png"),
                    infotxt: "基础服务",
                    desc: "客户接待、安全管理、工程运维、清洁绿化；"
                },
                {
                    imgsrc: require("../../assets/images/serviceArea/service06/2.2.png"),
                    infotxt: "专项服务",
                    desc:
                        "专属前台、行政助理、维修改造、绿植租摆、设备设施保养等；"
                },
                {
                    imgsrc: require("../../assets/images/serviceArea/service06/2.3.png"),
                    infotxt: "定制服务",
                    desc: "活动筹划实施、大型会务服务、商务庆典/接待等；"
                },
                {
                    imgsrc: require("../../assets/images/serviceArea/service06/2.4.png"),
                    infotxt: "延伸服务",
                    desc:
                        "办公采购、企业搬迁、装饰布置、家私租赁、交通餐饮、娱乐健身等。"
                }
            ],
            iconList: [
                {
                    text: "形象化",
                    imgsrc: require("../../assets/images/serviceArea/service06/1.png")
                },
                {
                    text: "细致化",
                    imgsrc: require("../../assets/images/serviceArea/service06/2.png")
                },
                {
                    text: "尖端化",
                    imgsrc: require("../../assets/images/serviceArea/service06/3.png")
                },
                {
                    text: "高质化",
                    imgsrc: require("../../assets/images/serviceArea/service06/4.png")
                },
                {
                    text: "策略化",
                    imgsrc: require("../../assets/images/serviceArea/service06/5.png")
                },
                {
                    text: "个性化",
                    imgsrc: require("../../assets/images/serviceArea/service06/6.png")
                }
            ],
            caseList: [
                {
                    caseTitle: "广州工控",
                    instructions:
                        "广州工业投资控股集团有限公司：拥有万宝、五羊、万力轮胎等多个知名品牌，以及山河智能（上市公司代码：002097）和金明精机（上市公司代码：300281）两家上市公司，企业总数达两百余家，员工总数3万余人。目前工控集团总部由我司物业管理事业部提供全面的服务保障。",
                    imageList: [
                        require("../../assets/images/serviceArea/service06/3.1.png"),
                        require("../../assets/images/serviceArea/service06/3.2.png"),
                        require("../../assets/images/serviceArea/service06/3.3.png"),
                        require("../../assets/images/serviceArea/service06/3.4.png")
                    ]
                }
            ],
            resObj: {
                caseList: [],
                caseColumn: {},
                caseColumnIntroduceList: []
            }
        };
    },
    mounted() {
        // console.log(this.CaseList);
        if (!!this.columnId) {
            this.getDataList();
        }
    },
    methods: {
        async getDataList() {
            // console.log(this.columnId);
            let res = await this.$request.getServiceTerritory({
                caseColumnId: this.columnId
            });
            if (res.code === 200) {
                // console.log(res);
                // this.reseList = res.rows;
                // this.reseList.map(item => {
                //     item.imageList = item.imageUrl.split(",");
                // });
                this.resObj = res;
                this.resObj.caseList.map(item => {
                    item.imageList = item.imageUrl.split(",");
                });
            }
        }
    },
    components: { serviceTit, serviceCase }
};
</script>

<style lang="scss" scoped>
.OfficeServices {
    width: 62.5vw;
    margin: 0 auto;
    .ServiceIntroduction {
        padding: 2.08vw 0 0 0;
        .servicecon {
            line-height: 1.35vw;
            text-indent: 1.67vw;
            color: #333333;
            font-size: 0.83vw;
            padding: 0.78vw 0.78vw;
            margin-top: 1.04vw;
            background-color: #f5f5f5;
            position: relative;
            text-align: justify;
            word-break: normal;
            &::before {
                position: absolute;
                left: 0;
                bottom: 0;
                display: block;
                content: "";
                width: 0.78vw;
                height: 0.78vw;
                border-left: 0.1vw solid #f3ca00;
                border-bottom: 0.1vw solid #f3ca00;
            }
            &::after {
                position: absolute;
                right: 0;
                top: 0;
                display: block;
                content: "";
                width: 0.78vw;
                height: 0.78vw;
                border-right: 0.1vw solid #f3ca00;
                border-top: 0.1vw solid #f3ca00;
            }
        }
        .servicecon_ul {
            width: 100%;
            padding-bottom: 2.08vw;
            li {
                width: 14.43vw;
                float: left;
                margin-right: 1.56vw;
                &:last-child {
                    margin-right: 0;
                }
                .servicecon_ul_img {
                    width: 14.48vw;
                    height: 9.17vw;
                    overflow: hidden;
                    &:hover img {
                        transform: scale(1.06, 1.06);
                    }
                    img {
                        transition: 0.6s ease-in-out;
                    }
                }
                .servicecon_ul_tit {
                    font-weight: bold;
                    text-align: center;
                }
                .servicecon_ul_li {
                    margin-top: 1.04vw;
                    position: relative;
                    line-height: 0.83vw;
                    span {
                        line-height: 1.25vw;
                        position: relative;
                        text-align: justify;
                    }
                }
            }
        }
        .list_icon {
            position: relative;
            height: 10.42vw;
            padding: 3.65vw 0 3.13vw;
            .icon_bg {
                width: 100vw;
                height: 13.54vw;
                background: url("../../assets/images/serviceArea/service06/beijing.png")
                    no-repeat;
                background-size: cover;
                position: absolute;
                top: 0;
                left: 50%;
                z-index: -1;
                margin: 0 0 0 -50vw;
            }
            .item_icon {
                display: inline-block;
                text-align: center;
                padding: 0 7.6vw 0 0;
                &:last-child {
                    padding: 0;
                }
                .i_text {
                    color: #ffffff;
                    font-size: 0.83vw;
                    padding: 0 0 1.25vw 0;
                }
                .i_text {
                    color: #ffffff;
                    font-size: 0.83vw;
                    padding: 0 0 1.25vw 0;
                }
                .i_icon {
                    width: 4.06vw;
                    img {
                        width: 100%;
                    }
                }
            }
        }
    }
    .ServiceCase {
        position: relative;
        padding-bottom: 3.13vw;
    }
}
@media (min-width: 1920px) {
    .OfficeServices {
        width: 1200px;
        margin: 0 auto;
        .ServiceIntroduction {
            padding: 40px 0 0 0;
            .servicecon {
                line-height: 26px;
                text-indent: 32px;
                color: #333333;
                font-size: 16px;
                padding: 15px 15px;
                margin-top: 20px;
                background-color: #f5f5f5;
                position: relative;
                text-align: justify;
                word-break: normal;
                &::before {
                    position: absolute;
                    left: 0;
                    bottom: 0;
                    display: block;
                    content: "";
                    width: 15px;
                    height: 15px;
                    border-left: 2px solid #f3ca00;
                    border-bottom: 2px solid #f3ca00;
                }
                &::after {
                    position: absolute;
                    right: 0;
                    top: 0;
                    display: block;
                    content: "";
                    width: 15px;
                    height: 15px;
                    border-right: 2px solid #f3ca00;
                    border-top: 2px solid #f3ca00;
                }
            }
            .servicecon_ul {
                width: 100%;
                padding-bottom: 40px;
                li {
                    width: 277px;
                    float: left;
                    margin-right: 30px;
                    &:last-child {
                        margin-right: 0;
                    }
                    .servicecon_ul_img {
                        width: 278px;
                        height: 176px;
                        overflow: hidden;
                        &:hover img {
                            transform: scale(1.06, 1.06);
                        }
                        img {
                            transition: 0.6s ease-in-out;
                        }
                    }
                    .servicecon_ul_tit {
                        font-weight: bold;
                        text-align: center;
                    }
                    .servicecon_ul_li {
                        margin-top: 20px;
                        position: relative;
                        line-height: 16px;
                        span {
                            line-height: 24px;
                            position: relative;
                            text-align: justify;
                        }
                    }
                }
            }
            .list_icon {
                position: relative;
                height: 200px;
                padding: 70px 0 60px;
                .icon_bg {
                    width: 1920px;
                    height: 260px;
                    background: url("../../assets/images/serviceArea/service06/beijing.png")
                        no-repeat;
                    background-size: cover;
                    position: absolute;
                    top: 0;
                    left: 50%;
                    z-index: -1;
                    margin: 0 0 0 -960px;
                }
                .item_icon {
                    display: inline-block;
                    text-align: center;
                    padding: 0 146px 0 0;
                    &:last-child {
                        padding: 0;
                    }
                    .i_text {
                        color: #ffffff;
                        font-size: 16px;
                        padding: 0 0 24px 0;
                    }
                    .i_icon {
                        width: 78px;
                        img {
                            width: 100%;
                        }
                    }
                }
            }
        }
        .ServiceCase {
            position: relative;
            padding-bottom: 60px;
        }
    }
}
</style>
