<template>
  <div class="tit">
    <b class="t-icon"></b>
    <span class="tit_ch fontsize20">{{ch}}</span>
    <span class="tit_en fontsize20">{{en}}</span>
  </div>
</template>
<script>
export default {
  props: ["ch", "en"]
};
</script>
<style lang="scss">
.tit{
.t-icon{
  display: inline-block;
  width: 30px;
  height: 16px;
  background: url('../../assets/images/serviceArea/titjb.png') no-repeat;
  background-size: cover;
}
.tit_ch {
    color: #333333;
    letter-spacing: 2px;
    font-weight: 700;
}
.tit_en {
  color: #333333;
  letter-spacing: 1px;
  opacity: 0.2;
  margin-left: 8px;
  font-weight: 700;
}
}

</style>