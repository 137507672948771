<template>
    <!-- 在线商城 -->
    <div class="MallService">
        <!-- 服务介绍 -->
        <div class="ServiceIntroduction">
            <serviceTit ch="服务介绍" en="SERVICE INTRODUCTION"></serviceTit>
            <div class="servicecon">
                {{ resObj.caseColumn.ramark }}
            </div>
        </div>
        <!-- 服务案例 -->
        <div class="ServiceCase">
            <serviceTit ch="服务案例" en="SERVICE CASE"></serviceTit>
            <serviceCase
                :caseObj="item"
                :index="index"
                v-for="(item, index) in resObj.caseList"
                :key="index"
            >
            </serviceCase>
        </div>
    </div>
</template>

<script>
import serviceTit from "@/components/common/servicetit";
import serviceCase from "@/components/common/serviceCase";

export default {
    data() {
        return {
            columnId: 58,
            pageNo: 1,
            pageSize: 8,
            servicecon:
                "提供优质、便捷、丰富的生活服务，为员工、客户提供多样性选择。",
            caseList: [
                {
                    caseTitle: "在线商城",
                    instructions:
                        "在线商城：利用电子商务的各种手段，达成从买到卖的过程的虚拟商店，从而减少中间环节，消除运输成本和代理中间的差价，造就对普通消费和加大市场流通带来巨大的发展空间。",
                    imageList: [
                        require("../../assets/images/serviceArea/service07/1.png"),
                        require("../../assets/images/serviceArea/service07/2.png"),
                        require("../../assets/images/serviceArea/service07/3.png"),
                        require("../../assets/images/serviceArea/service07/4.png")
                    ]
                }
            ],
            resObj: {
                caseList: [],
                caseColumn: {},
                caseColumnIntroduceList: []
            }
        };
    },
    mounted() {
        // console.log(this.CaseList);
        if (!!this.columnId) {
            this.getDataList();
        }
    },
    methods: {
        async getDataList() {
            // console.log(this.columnId);
            let res = await this.$request.getServiceTerritory({
                caseColumnId: this.columnId
            });
            if (res.code === 200) {
                // console.log(res);
                // this.reseList = res.rows;
                // this.reseList.map(item => {
                //     item.imageList = item.imageUrl.split(",");
                // });
                this.resObj = res;
                this.resObj.caseList.map(item => {
                    item.imageList = item.imageUrl.split(",");
                });
                this.resObj.caseColumnIntroduceList.map(item => {
                    item.infoDescribe = JSON.parse(item.infoDescribe);
                });
            }
        }
    },
    components: { serviceTit, serviceCase }
};
</script>

<style lang="scss" scoped>
.MallService {
    width: 62.5vw;
    margin: 0 auto;
    .ServiceIntroduction {
        padding: 2.08vw 0 0 0;
        .servicecon {
            line-height: 1.25vw;
            text-indent: 1.67vw;
            color: #333333;
            font-size: 0.83vw;
            padding: 0.83vw 0.78vw;
            margin-top: 1.04vw;
            background-color: #f5f5f5;
            position: relative;
            text-align: justify;
            word-break: normal;
            &::before {
                position: absolute;
                left: 0;
                bottom: 0;
                display: block;
                content: "";
                width: 0.78vw;
                height: 0.78vw;
                border-left: 0.1vw solid #f3ca00;
                border-bottom: 0.1vw solid #f3ca00;
            }
            &::after {
                position: absolute;
                right: 0;
                top: 0;
                display: block;
                content: "";
                width: 0.78vw;
                height: 0.78vw;
                border-right: 0.1vw solid #f3ca00;
                border-top: 0.1vw solid #f3ca00;
            }
        }
        .servicecon_ul {
            width: 100%;
            padding-bottom: 2.08vw;
            li {
                width: 20%;
                float: left;
                text-align: center;
                .servicecon_ul_img {
                    width: 11.67vw;
                    height: 7.29vw;
                    overflow: hidden;
                    &:hover img {
                        transform: scale(1.06, 1.06);
                    }
                    img {
                        transition: 0.6s ease-in-out;
                    }
                }
                .servicecon_ul_tit {
                    font-weight: bold;
                }
                .servicecon_ul_li {
                    margin-top: 0.83vw;
                    position: relative;
                    line-height: 0.83vw;
                    span {
                        position: relative;
                        &::before {
                            display: inline-block;
                            content: "";
                            width: 0.26vw;
                            height: 0.26vw;
                            position: absolute;
                            top: 50%;
                            left: -0.52vw;
                            margin: -0.13vw 0 0 0;
                            background-color: #f3ca00;
                            border-radius: 50%;
                        }
                    }
                }
            }
        }
    }
    .ServiceCase {
        position: relative;
        padding: 3.13vw 0;
    }
}
@media (min-width: 1920px) {
    .MallService {
        width: 1200px;
        margin: 0 auto;
        .ServiceIntroduction {
            padding: 40px 0 0 0;
            .servicecon {
                line-height: 24px;
                text-indent: 32px;
                color: #333333;
                font-size: 16px;
                padding: 16px 15px;
                margin-top: 20px;
                background-color: #f5f5f5;
                position: relative;
                text-align: justify;
                word-break: normal;
                &::before {
                    position: absolute;
                    left: 0;
                    bottom: 0;
                    display: block;
                    content: "";
                    width: 15px;
                    height: 15px;
                    border-left: 2px solid #f3ca00;
                    border-bottom: 2px solid #f3ca00;
                }
                &::after {
                    position: absolute;
                    right: 0;
                    top: 0;
                    display: block;
                    content: "";
                    width: 15px;
                    height: 15px;
                    border-right: 2px solid #f3ca00;
                    border-top: 2px solid #f3ca00;
                }
            }
            .servicecon_ul {
                width: 100%;
                padding-bottom: 40px;
                li {
                    width: 20%;
                    float: left;
                    text-align: center;
                    .servicecon_ul_img {
                        width: 224px;
                        height: 140px;
                        overflow: hidden;
                        &:hover img {
                            transform: scale(1.06, 1.06);
                        }
                        img {
                            transition: 0.6s ease-in-out;
                        }
                    }
                    .servicecon_ul_tit {
                        font-weight: bold;
                    }
                    .servicecon_ul_li {
                        margin-top: 16px;
                        position: relative;
                        line-height: 16px;
                        span {
                            position: relative;
                            &::before {
                                display: inline-block;
                                content: "";
                                width: 5px;
                                height: 5px;
                                position: absolute;
                                top: 50%;
                                left: -10px;
                                margin: -2.5px 0 0 0;
                                background-color: #f3ca00;
                                border-radius: 50%;
                            }
                        }
                    }
                }
            }
        }
        .ServiceCase {
            position: relative;
            padding: 60px 0;
        }
    }
}
</style>
