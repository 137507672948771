<template>
    <!-- 城市综合管廊运维 -->
    <div class="PipeGallery">
        <!-- 服务介绍 -->
        <div class="ServiceIntroduction">
            <serviceTit ch="服务介绍" en="SERVICE INTRODUCTION"></serviceTit>
            <div class="servicecon">
                {{ resObj.caseColumn.ramark }}
            </div>
            <ul class=" servicecon_ul  marginT30 clear">
                <li
                    v-for="(item, index) in resObj.caseColumnIntroduceList"
                    :key="index"
                >
                    <div class="servicecon_ul_img">
                        <img :src="$store.state.baseUrl + item.imageUrl" />
                    </div>
                    <div class="servicecon_ul_con">
                        <div
                            class="servicecon_ul_tit color333 fontsize18 marginT15"
                        >
                            {{ item.title }}
                        </div>
                        <div
                            v-for="(item1, index1) in item.infoDescribe"
                            :key="index1"
                            class="servicecon_ul_li color666 fontsize16"
                        >
                            <span>{{ item1 }}</span>
                        </div>
                    </div>
                </li>
            </ul>
        </div>
        <!-- 服务案例 -->
        <div class="ServiceCase">
            <serviceTit ch="服务案例" en="SERVICE CASE"></serviceTit>
            <!-- <div
                :class="['caseItem'+(index-0+1),'caseItem clear']"
                v-for="(item, index) in caseList"
                :key="index"
            >
                <div class="c-txt">
                    <div class="c-title">{{ item.caseTitle }}</div>
                    <div class="c-content">
                        {{ item.instructions }}
                    </div>
                    <b class="num1"></b>
                    <b class="num2"></b>
                    <b class="jiantou"></b>
                </div>
                <div class="c-img">
                    <swiperCpt
                        class="swiperCaseImg CaseImg_list"
                        ref="swiperCaseImg"
                        :slidesPerView="4"
                        :loop="true"
                    >
                        <div
                            class="swiper-slide"
                            v-for="(item1, index1) in item.imageList"
                            :key="'swiperCaseImg' + index1"
                        >
                            <div class="Case_img">
                                <img
                                    :src="item1"
                                    :alt="'swiperCaseImg' + index1"
                                />
                            </div>
                        </div>
                    </swiperCpt>
                </div>
            </div> -->
            <serviceCase
                :caseObj="item"
                :index="index"
                v-for="(item, index) in resObj.caseList"
                :key="index"
            >
            </serviceCase>
        </div>
    </div>
</template>

<script>
import serviceTit from "@/components/common/servicetit";
import serviceCase from "@/components/common/serviceCase";

export default {
    data() {
        return {
            columnId: 51,
            pageNo: 1,
            pageSize: 8,
            servicecon:
                "具备17年管廊运维经验，先后承接广州、佛山、珠海等重点管廊项目。参与国家标准制定，主持编写地方标准，发起成立了中机维协管廊分会。",
            ServiceIntroduction: [
                {
                    imgsrc: require("../../assets/images/serviceArea/service01/zixun.png"),
                    infotxt: "咨询服务",
                    infoArr: ["方案优化", "费用测算", "人员培训"]
                },
                {
                    imgsrc: require("../../assets/images/serviceArea/service01/jishu.png"),
                    infotxt: "技术服务",
                    infoArr: ["技术编制", "技术支持", "技术诊断"]
                },
                {
                    imgsrc: require("../../assets/images/serviceArea/service01/biaozhun.png"),
                    infotxt: "标准服务",
                    infoArr: ["规范起草", "标准编写", "标准审核"]
                },
                {
                    imgsrc: require("../../assets/images/serviceArea/service01/yunwei.png"),
                    infotxt: "运维服务",
                    infoArr: ["委托运维", "设备点检", "配件供应"]
                },
                {
                    imgsrc: require("../../assets/images/serviceArea/service01/gongcheng.png"),
                    infotxt: "工程服务",
                    infoArr: ["工程施工", "设备检修", "项目管理"]
                }
            ],
            caseList: [
                {
                    caseTitle: "咨询与技术服务",
                    instructions:
                        "主要为城市综合体项目设计和提供技术方案、技术指导、管理方案等。服务案例包括广州地铁集团交通枢纽中心、武汉光谷中心区地下综合管廊、安徽界首地下综合管廊、四川绵阳地下综合管廊、肇庆新区地下综合管廊、广西南宁地下综合管廊、华南理工大学研究广州管廊等众多项目。",
                    imageList: [
                        require("../../assets/images/serviceArea/service02/jishuyuzixun1.png"),
                        require("../../assets/images/serviceArea/service02/jishuyuzixun2.png"),
                        require("../../assets/images/serviceArea/service02/jishuyuzixun3.png"),
                        require("../../assets/images/serviceArea/service02/jishuyuzixun4.png")
                    ]
                },
                {
                    caseTitle: "标准服务",
                    instructions:
                        "参与国家、地方、行业标准的制定，是国家标准化委员会《城市综合管廊运营服务规范》参编单位，广州市住房和城乡建设局《广州市城市地下综合管廊运营管理标准》主编单位，广州市住房和城乡建设局《广州市城市地下综合管廊运营管理标准》主编单位等等。",
                    imageList: [
                        require("../../assets/images/serviceArea/service02/biaozhun1.png"),
                        require("../../assets/images/serviceArea/service02/biaozhun2.png"),
                        require("../../assets/images/serviceArea/service02/biaozhun3.png"),
                        require("../../assets/images/serviceArea/service02/biaozhun4.png")
                    ]
                },
                {
                    caseTitle: "大型场馆",
                    instructions:
                        "中国进出口商品交易会(广交会)展馆:作为机电设备运维行业的开拓者，我司机电运维事业部连续17年为广交会展馆提供设备设施综合运行管理服务，先后保障34届广交会及各项重大展览活动的举办，多次被评为广交会优秀外包单位，是广交会多年发展最值得信赖的合作伙伴之一。",
                    imageList: [
                        require("../../assets/images/serviceArea/service02/daxingchangguan1.png"),
                        require("../../assets/images/serviceArea/service02/daxingchangguan2.png"),
                        require("../../assets/images/serviceArea/service02/daxingchangguan3.png"),
                        require("../../assets/images/serviceArea/service02/daxingchangguan4.png")
                    ]
                },
                {
                    caseTitle: "城市公共",
                    instructions:
                        "广州大学城：是华南地区高级人才培养、科学研究和交流的中心，学、研、产一体化发展的国家一流大学园区。其中，广州大学城综合管廊全长18公里，是当时国内距离最长、规模最大、体系最完整的一条综合管廊。我司机电运维事业部受广州市政府重点项目办委托，2004年开始先后承接了大学城区域供冷、集中供热能源管理项目和大学城综合管廊的机电设备管理项目。",
                    imageList: [
                        require("../../assets/images/serviceArea/service02/daxuecheng1.png"),
                        require("../../assets/images/serviceArea/service02/daxuecheng2.png"),
                        require("../../assets/images/serviceArea/service02/daxuecheng3.png"),
                        require("../../assets/images/serviceArea/service02/daxuecheng4.png")
                    ]
                },
                {
                    caseTitle: "城市公共",
                    instructions:
                        "珠海横琴新区：为国家级新区、是中国“粤港澳”合作新模式的示范区。我司机电运维事业部受珠海大横琴投资公司邀请，曾先后承接珠海横琴新区综合管廊一期的运维管理服务，以及横琴新区长达33.4公里的地下综合管廊管理服务。",
                    imageList: [
                        require("../../assets/images/serviceArea/service02/zhuhai1.png"),
                        require("../../assets/images/serviceArea/service02/zhuhai2.png"),
                        require("../../assets/images/serviceArea/service02/zhuhai3.png"),
                        require("../../assets/images/serviceArea/service02/zhuhai4.png")
                    ]
                }
            ],
            resObj: {
                caseList: [],
                caseColumn: {},
                caseColumnIntroduceList: []
            }
        };
    },
    mounted() {
        if (!!this.columnId) {
            this.getDataList();
        }
    },
    methods: {
        async getDataList() {
            // console.log(this.columnId);
            let res = await this.$request.getServiceTerritory({
                caseColumnId: this.columnId
            });
            if (res.code === 200) {
                // console.log(res);
                // this.reseList = res.rows;
                // this.reseList.map(item => {
                //     item.imageList = item.imageUrl.split(",");
                // });
                this.resObj = res;
                this.resObj.caseList.map(item => {
                    item.imageList = item.imageUrl.split(",");
                });
                this.resObj.caseColumnIntroduceList.map(item => {
                    item.infoDescribe = JSON.parse(item.infoDescribe);
                });
            }
        }
    },
    // watch: {
    //     columnId(newVal, oldVal) {
    //         if (newVal && newVal != oldVal) {
    //             this.getDataList();
    //         }
    //     }
    // },
    components: { serviceTit, serviceCase }
};
</script>

<style lang="scss" scoped>
.PipeGallery {
    width: 62.5vw;
    margin: 0 auto;
    .ServiceIntroduction {
        padding: 2.08vw 0 0 0;
        .servicecon {
            line-height: 1.25vw;
            text-indent: 1.67vw;
            color: #333333;
            font-size: 0.83vw;
            padding: 0.83vw 0.78vw;
            margin-top: 1.04vw;
            background-color: #f5f5f5;
            position: relative;
            text-align: justify;
            word-break: normal;
            &::before {
                position: absolute;
                left: 0;
                bottom: 0;
                display: block;
                content: "";
                width: 0.78vw;
                height: 0.78vw;
                border-left: 0.1vw solid #f3ca00;
                border-bottom: 0.1vw solid #f3ca00;
            }
            &::after {
                position: absolute;
                right: 0;
                top: 0;
                display: block;
                content: "";
                width: 0.78vw;
                height: 0.78vw;
                border-right: 0.1vw solid #f3ca00;
                border-top: 0.1vw solid #f3ca00;
            }
        }
        .servicecon_ul {
            width: 100%;
            padding-bottom: 2.08vw;
            li {
                width: 20%;
                float: left;
                text-align: center;
                .servicecon_ul_img {
                    width: 11.67vw;
                    height: 7.29vw;
                    overflow: hidden;
                    &:hover img {
                        transform: scale(1.06, 1.06);
                    }
                    img {
                        transition: 0.6s ease-in-out;
                    }
                }
                .servicecon_ul_tit {
                    font-weight: bold;
                }
                .servicecon_ul_li {
                    margin-top: 0.83vw;
                    position: relative;
                    line-height: 0.83vw;
                    span {
                        position: relative;
                        &::before {
                            display: inline-block;
                            content: "";
                            width: 0.26vw;
                            height: 0.26vw;
                            position: absolute;
                            top: 50%;
                            left: -0.52vw;
                            margin: -0.13vw 0 0 0;
                            background-color: #f3ca00;
                            border-radius: 50%;
                        }
                    }
                }
            }
        }
    }
    .ServiceCase {
        position: relative;
        padding-bottom: 3.13vw;
    }
}
@media (min-width: 1920px) {
    .PipeGallery {
        width: 1200px;
        margin: 0 auto;
        .ServiceIntroduction {
            padding: 40px 0 0 0;
            .servicecon {
                line-height: 24px;
                text-indent: 32px;
                color: #333333;
                font-size: 16px;
                padding: 16px 15px;
                margin-top: 20px;
                background-color: #f5f5f5;
                position: relative;
                text-align: justify;
                word-break: normal;
                &::before {
                    position: absolute;
                    left: 0;
                    bottom: 0;
                    display: block;
                    content: "";
                    width: 15px;
                    height: 15px;
                    border-left: 2px solid #f3ca00;
                    border-bottom: 2px solid #f3ca00;
                }
                &::after {
                    position: absolute;
                    right: 0;
                    top: 0;
                    display: block;
                    content: "";
                    width: 15px;
                    height: 15px;
                    border-right: 2px solid #f3ca00;
                    border-top: 2px solid #f3ca00;
                }
            }
            .servicecon_ul {
                width: 100%;
                padding-bottom: 40px;
                li {
                    width: 20%;
                    float: left;
                    text-align: center;
                    .servicecon_ul_img {
                        width: 224px;
                        height: 140px;
                        overflow: hidden;
                        &:hover img {
                            transform: scale(1.06, 1.06);
                        }
                        img {
                            transition: 0.6s ease-in-out;
                        }
                    }
                    .servicecon_ul_tit {
                        font-weight: bold;
                    }
                    .servicecon_ul_li {
                        margin-top: 16px;
                        position: relative;
                        line-height: 16px;
                        span {
                            position: relative;
                            &::before {
                                display: inline-block;
                                content: "";
                                width: 5px;
                                height: 5px;
                                position: absolute;
                                top: 50%;
                                left: -10px;
                                margin: -2.5px 0 0 0;
                                background-color: #f3ca00;
                                border-radius: 50%;
                            }
                        }
                    }
                }
            }
        }
        .ServiceCase {
            position: relative;
            padding-bottom: 60px;
        }
    }
}
</style>
