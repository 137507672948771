<template>
  <div :class="['caseItem' + (index - 0 + 1), 'caseItem clear']">
    <div class="c-txt">
      <div class="c-title">{{ caseObj.caseTitle }}</div>
      <div class="c-content" v-html="caseObj.instructions"></div>
      <b class="num1">0{{ index - 0 + 1 }}</b>
      <b class="num2">0{{ index - 0 + 1 }}</b>
      <b class="jiantou"></b>
    </div>
    <div class="c-img">
      <swiperCpt
        class="swiperCaseImg CaseImg_list"
        ref="swiperCaseImg"
        :slidesPerView="4"
        :loop="false"
        :delay="3000"
        :autoplay="true"
        :sid="'swiperCaseImg' + index"
        v-if="caseObj.imageList.length > 0"
      >
        <div
          class="swiper-slide"
          v-for="(item1, index1) in caseObj.imageList"
          :key="'swiperCaseImg' + index1"
        >
          <div class="Case_img">
            <img
              :src="$store.state.baseUrl + item1"
              :alt="'swiperCaseImg' + index1"
            />
          </div>
        </div>
      </swiperCpt>
    </div>
  </div>
</template>
<script>
import swiperCpt from "@/components/common/swiperCpt.vue";
export default {
  props: ["index", "caseObj"],
  components: { swiperCpt },
  watch: {
    caseObj: {
      handler(newValue, oldValue) {
        this.caseObj = newValue;
      },
      deep: true,
    },
  },
};
</script>
<style lang="scss">
.caseItem {
  padding: 1.56vw 0 0.52vw 0;
  position: relative;
  .c-txt {
    width: 18.54vw;
    height: 14.06vw;
    padding: 1.56vw 8.33vw 0 1.04vw;
    background-color: #ffffff;
    box-shadow: 0vw 0.21vw 0.52vw 0vw rgba(0, 0, 0, 0.07);
    position: absolute;
    top: 1.56vw;
    left: 0;
    .c-title {
      padding: 0 0 1.04vw 3.13vw;
      font-size: 1.04vw;
      font-weight: bold;
      letter-spacing: 0.1vw;
      color: #333333;
    }
    .c-content {
      font-size: 0.83vw;
      line-height: 1.25vw;
      letter-spacing: 0.05vw;
      color: #666;
      text-indent: 1.77vw;
      strong {
        color: #333;
      }
    }
    .num1 {
      display: block;
      width: 3.23vw;
      height: 2.08vw;
      position: absolute;
      top: 0.83vw;
      left: 0.63vw;
      font-size: 2.81vw;
      color: #f3ca00;
      font-style: oblique;
    }
    .num2 {
      display: block;
      width: 5.83vw;
      height: 3.65vw;
      position: absolute;
      right: 8.85vw;
      bottom: 1.04vw;
      font-size: 4.38vw;
      color: #f3ca00;
      opacity: 0.2;
      font-style: oblique;
    }
    .jiantou {
      display: block;
      width: 3.44vw;
      height: 0.52vw;
      position: absolute;
      right: 9.38vw;
      top: 1.88vw;
      background: url("../../assets/images/common/jiantouRight.png") no-repeat;
      background-size: cover;
    }
  }
  .c-img {
    width: 41.67vw;
    height: 15.63vw;
    padding: 0 0 0 20.83vw;
    .CaseImg_list {
      height: 14.06vw;
      padding: 1.56vw 0 0 0;
      .swiper-slide {
        .Case_img {
          width: 10vw;
          height: 12.24vw;
          border: solid 0.05vw #eeeeee;
          overflow: hidden;
          margin: 0 0.52vw 0 0;
          &:hover img {
            transform: scale(1.06, 1.06);
          }
          img {
            transition: 0.6s ease-in-out;
            width: 100%;
          }
        }
      }
    }
  }
}
@media (min-width: 1920px) {
  .caseItem {
    padding: 30px 0 10px 0;
    position: relative;
    .c-txt {
      width: 356px;
      height: 270px;
      padding: 30px 160px 0 20px;
      background-color: #ffffff;
      box-shadow: 0px 4px 10px 0px rgba(0, 0, 0, 0.07);
      position: absolute;
      top: 30px;
      left: 0;
      .c-title {
        padding: 0 0 20px 60px;
        font-size: 20px;
        font-weight: bold;
        letter-spacing: 2px;
        color: #333333;
      }
      .c-content {
        font-size: 16px;
        line-height: 24px;
        letter-spacing: 1px;
        color: #666;
        text-indent: 34px;
        strong {
          color: #333;
        }
      }
      .num1 {
        display: block;
        width: 62px;
        height: 40px;
        position: absolute;
        top: 16px;
        left: 12px;
        font-size: 54px;
        color: #f3ca00;
        font-style: oblique;
      }
      .num2 {
        display: block;
        width: 112px;
        height: 70px;
        position: absolute;
        right: 170px;
        bottom: 20px;
        font-size: 84px;
        color: #f3ca00;
        opacity: 0.2;
        font-style: oblique;
      }
      .jiantou {
        display: block;
        width: 66px;
        height: 10px;
        position: absolute;
        right: 180px;
        top: 36px;
        background: url("../../assets/images/common/jiantouRight.png") no-repeat;
        background-size: cover;
      }
    }
    .c-img {
      width: 800px;
      height: 300px;
      padding: 0 0 0 400px;
      .CaseImg_list {
        height: 270px;
        padding: 30px 0 0 0;
        .swiper-slide {
          .Case_img {
            width: 192px;
            height: 235px;
            border: solid 1px #eeeeee;
            overflow: hidden;
            margin: 0 10px 0 0;
            &:hover img {
              transform: scale(1.06, 1.06);
            }
            img {
              transition: 0.6s ease-in-out;
              width: 100%;
            }
          }
        }
      }
    }
  }
}
</style>
